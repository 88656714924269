@import "../node_modules/bootstrap/scss/functions";
@import "../node_modules/bootstrap/scss/variables";

//BRANDING COLORS
//--teal: #20c997;
//--white: #fff;
//--blue: #007bff;
//--gray: #6c757d;
//--info: #17a2b8;
//--warning: #ffc107;
//--danger: #dc3545;
//--light: #f8f9fa;
//--dark: #343a40;

$primary-teal: $teal;
$white: #fcfcfc;
$secondary-blue: $blue;
$green: #a0ce0d;
$gray: $gray-800;
$light-gray: $gray-500;

$theme-colors: (
  "dark": $gray,
  "info": #f6f9fc,
  "primary": $primary-teal,
  "secondary": $secondary-blue,
);

@import "../node_modules/bootstrap/scss/bootstrap";

* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

a {
  color: $primary;
  cursor: pointer;
  text-decoration: dotted;
}
a:hover {
  text-decoration: none !important;
  color: $gray;
}

// .navbar {
//   min-height: 25px;
// }

.navbar-brand {
  padding: 0 15px;
  height: 25px;
  line-height: 25px;
}

.navbar-toggle {
  /* (25px - button height 34px) / 2 = 8px */
  margin-top: -4.5px;
  padding: 9px 10px !important;
}

.recipe-feature-image {
  height: calc(100vh * 0.4);
  object-fit: cover;
}

.recipe-card-image {
  height: calc(100vh * 0.18);
  object-fit: cover;
}

.mini-recipe-card-image {
  height: calc(100vh * 0.1);
  object-fit: cover;
}

.card-text {
  font-size: smaller;
}

.recipe-text {
  font-size: smaller;
}

.card-footer-text {
  font-size: smaller;
}

.profile-image {
  height: 200px !important;
  width: 200px !important;
}
.recipe-page-title {
  font-family: "Playfair Display", Georgia, "Times New Roman", serif;
  margin-top: -3.5rem !important;
  font-size: 2rem !important;
}

.product-card-ad {
  height: 80px;
  width: 40%;
  align-self: center;
  display: block;
}

@media (min-width: 768px) {
  .navbar-nav > li > a {
    /* (25px - line-height of 27px) / 2 = 1px */
    padding-top: 1px;
    padding-bottom: 1px;
    line-height: 27px;
  }

  .recipe-feature-image {
    height: calc(100vh * 0.42);
    object-fit: cover;
  }
  .recipe-card-image {
    height: calc(100vh * 0.4);
    object-fit: cover;
  }

  .product-card-ad {
    height: auto;
    width: 60%;
    align-self: center;
    align-content: center;
    display: block;
  }
  .recipe-page-title {
    font-family: "Playfair Display", Georgia, "Times New Roman", serif;
    margin-top: -3.5rem !important;
    font-size: 3.5rem !important;
  }
}

.produce-card {
  object-fit: cover;
  height: 200px;
}

.product-card {
  object-fit: scale-down;
  height: 200px;
}

img {
  width: 100%;
}

.recipe-form-image {
  max-height: 300px;
}

.site-title {
  font-family: "Playfair Display", Georgia, "Times New Roman", serif;
}

.recipe-title {
  font-family: "Playfair Display", Georgia, "Times New Roman", serif;
  font-size: x-large;
  color: $dark;
}

.method-paragraph {
  line-height: 2rem;
}

// .landing {
//   position: relative;
//   height: 100vh;
//   margin-top: -24px;
//   margin-bottom: -50px;
// }

// .landing-inner {
//   padding-top: 80px;
//   padding-bottom: 100px;
// }

.no-resize {
  resize: none;
  overflow: hidden;
}

// .dark-overlay {
//   background-color: rgba(122, 122, 122, 0.6);
//   position: absolute;
//   top: 0;
//   left: 0;
//   width: 100%;
//   height: 100%;
// }

.form-control::placeholder {
  color: $light-gray !important;
  font-style: italic;
}

.card-columns {
  @include media-breakpoint-only(xl) {
    column-count: 5;
  }
  @include media-breakpoint-only(lg) {
    column-count: 4;
  }
  @include media-breakpoint-only(md) {
    column-count: 3;
  }
  @include media-breakpoint-only(sm) {
    column-count: 2;
  }
}

.card-columns-large {
  @include media-breakpoint-only(xl) {
    column-count: 4;
  }
  @include media-breakpoint-only(lg) {
    column-count: 3;
  }
  @include media-breakpoint-only(md) {
    column-count: 2;
  }
  @include media-breakpoint-only(sm) {
    column-count: 1;
  }
}

/* MARKETING CONTENT
-------------------------------------------------- */

/* Center align the text within the three columns below the carousel */
.marketing .col-lg-4 {
  margin-bottom: 1.5rem;
  text-align: center;
}
.marketing h2 {
  font-weight: 400;
}
.marketing .col-lg-4 p {
  margin-right: 0.75rem;
  margin-left: 0.75rem;
}

/* Featurettes
------------------------- */

.featurette-divider {
  margin: 1rem 0; /* Space out the Bootstrap <hr> more */
}

/* Thin out the marketing headings */
.featurette-heading {
  font-weight: 300;
  line-height: 1;
  letter-spacing: -0.05rem;
}

/* RESPONSIVE CSS
-------------------------------------------------- */

@media (min-width: 40em) {
  /* Bump up size of carousel content */
  .carousel-caption p {
    margin-bottom: 1.25rem;
    font-size: 1.25rem;
    line-height: 1.4;
  }

  .featurette-heading {
    font-size: 50px;
  }
}

@media (min-width: 62em) {
  .featurette-heading {
    margin-top: 2rem;
  }
}

.lift:focus,
.lift:hover {
  -webkit-transform: translateY(-3px);
  transform: translateY(-3px);
  transition-duration: 1s;
}

.lift-lg:focus,
.lift-lg:hover {
  -webkit-transform: translateY(-5px);
  transform: translateY(-5px);
  transition-duration: 0.5s;
}

.shadow-dark {
  box-shadow: 0 0.5rem 1.5rem rgba(22, 28, 45, 0.15) !important;
}

.shadow-dark-lg {
  box-shadow: 0 1.5rem 4rem rgba(22, 28, 45, 0.15) !important;
}

.shadow-lift {
  box-shadow: 0 1rem 2.5rem rgba(22, 28, 45, 0.1),
    0 0.5rem 1rem -0.75rem rgba(22, 28, 45, 0.1) !important;
}

.shape {
  position: absolute;
  pointer-events: none;
}

.shape > * {
  display: block;
}

.shape:not([class*="shape-blur"]) {
  overflow: hidden;
}

.shape:not([class*="shape-blur"]) > * {
  -webkit-transform: scale(2);
  transform: scale(2);
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .shape > svg {
    display: none;
  }
}

.shape-top {
  top: 0;
  right: 0;
  left: 0;
}

.shape-top > * {
  -webkit-transform-origin: bottom center;
  transform-origin: bottom center;
}

.shape-right {
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.shape-right > * {
  position: inherit;
  top: inherit;
  bottom: inherit;
  right: inherit;
  -webkit-transform-origin: center left;
  transform-origin: center left;
}

.shape-bottom {
  right: 0;
  bottom: 0;
  left: 0;
}

.shape-bottom > * {
  -webkit-transform-origin: top center;
  transform-origin: top center;
}

.shape-bottom-100 {
  right: 0;
  bottom: 100%;
  left: 0;
}

.shape-bottom-100 > * {
  -webkit-transform-origin: top center;
  transform-origin: top center;
}

.shape-left {
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.shape-left > * {
  position: inherit;
  top: inherit;
  bottom: inherit;
  left: inherit;
  -webkit-transform-origin: center right;
  transform-origin: center right;
}

.shape-fluid-x > * {
  width: 100%;
  height: auto;
}

.shape-fluid-y > * {
  width: auto;
  height: 100%;
}

.shape-blur-1 {
  top: 0;
  right: 0;
  left: 0;
}

@media (min-width: 768px) {
  .shape-blur-1 {
    bottom: 0;
    left: 50%;
    right: auto;
  }
}

.shape-blur-1 > * {
  width: 100%;
  height: auto;
}

@media (min-width: 768px) {
  .shape-blur-1 > * {
    width: auto;
    height: 100%;
  }
}

.shape-blur-2 {
  top: 0;
  right: 0;
  left: 0;
}

@media (min-width: 768px) {
  .shape-blur-2 {
    bottom: 0;
    right: 50%;
    left: auto;
  }
}

.shape-blur-2 > * {
  width: 100%;
  height: auto;
}

@media (min-width: 768px) {
  .shape-blur-2 > * {
    width: auto;
    height: 100%;
    float: right;
  }
}

.shape-blur-3 {
  top: 0;
  right: 0;
  left: 0;
}

.shape-blur-3 > * {
  width: 100%;
  height: auto;
}

.shape-blur-4 {
  top: 0;
  bottom: 0;
  left: 50%;
  -webkit-transform: translateX(-50%) scale(1.3);
  transform: translateX(-50%) scale(1.3);
}

.shape-blur-4 > * {
  width: auto;
  height: 100%;
}

.position-relative {
  position: relative !important;
}

.card-body,
.card-footer,
.card-img,
.card-img-left,
.card-img-right,
.card-img-top,
.card-meta {
  position: relative;
  min-height: 1px;
}

svg {
  overflow: hidden;
  vertical-align: middle;
}

@media (min-width: 768px) {
  .card-row .card-meta {
    padding-left: 2.5rem;
    padding-right: 2.5rem;
  }
}

.card-meta {
  display: -webkit-box;
  display: flex;
  flex-wrap: wrap;
  -webkit-box-align: center;
  align-items: center;
  padding-left: 1rem;
  padding-right: 1rem;
  padding-bottom: 0.5rem;
}

.card-meta-divider {
  width: 100%;
  margin: 0 0 1.5rem;
}

/*------------------------------------
  Avatar
------------------------------------*/

.avatar {
  position: relative;
  display: inline-block;
  width: 1.875rem;
  height: 1.875rem;
  font-size: 0.625rem;
}

.avatar-img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
}
.avatar-initials {
  font-size: 1rem;
  font-weight: 600;
}

.avatar-circle {
  border-radius: 50%;
}

.avatar-circle .avatar-img {
  border-radius: 50%;
}

.avatar-xs {
  width: 1rem;
  height: 1rem;
  font-size: 0.33333rem;
}

.avatar-sm {
  width: 1.5rem;
  height: 1.5rem;
  font-size: 0.5rem;
}

.avatar-lg {
  width: 2.25rem;
  height: 2.25rem;
  font-size: 0.75rem;
}

.avatar-xl {
  width: 4rem;
  height: 4rem;
  font-size: 1.33333rem;
}

.avatar-xxl {
  width: 7rem;
  height: 7rem;
  font-size: 1.33333rem;
}

@media (min-width: 768px) {
  .avatar-xxl {
    width: 7rem;
    height: 7rem;
    font-size: 1.66667rem;
  }
}

.badge {
  display: inline-block;
  padding: 0.25rem 0.75rem;
  font-size: 75%;
  font-weight: 600;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.375rem;
  -webkit-transition: color 0.15s ease-in-out,
    background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .badge {
    -webkit-transition: none;
    transition: none;
  }
}

a.badge:focus,
a.badge:hover {
  text-decoration: none;
}

.badge:empty {
  display: none;
}

.btn .badge {
  position: relative;
  top: -1px;
}
.btn-link:hover {
  text-decoration: none;
}

.btn-wide {
  min-width: 15rem;
}

.badge-pill {
  padding-right: 0.75rem;
  padding-left: 0.75rem;
  border-radius: 10rem;
}

.badge-lg {
  padding: 0.7em 1.15em;
}

.badge-rounded-circle {
  height: calc(1em + 0.5rem);
  padding-left: 0.25rem;
  padding-right: 0.25rem;
  border-radius: 10rem;
}

.badge-rounded-circle > * {
  display: block;
  width: 1em;
}

.badge-rounded-circle.badge-lg {
  height: calc(1em + 1.4em);
  padding-left: 0.7em;
  padding-right: 0.7em;
}

.badge-float {
  position: absolute;
  z-index: 1000;
}

.badge-float-outside {
  top: -0.5rem;
  right: -0.5rem;
}

.badge-float-inside {
  top: 1rem;
  right: 1rem;
}

.form-control {
  font-size: 1rem;
}

/////////////////////
.nav {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.nav-link {
  display: block;
  padding: 0.5rem 1rem;
}

.nav-link:hover,
.nav-link:focus {
  text-decoration: none;
}

.nav-link.disabled {
  color: #8c98a4;
  pointer-events: none;
  cursor: default;
}

.nav-tabs {
  border-bottom: 0.0625rem solid #e7eaf3;
}

.nav-tabs .nav-item {
  margin-bottom: -0.0625rem;
}

.nav-tabs .nav-link {
  border: 0.0625rem solid transparent;
  border-top-left-radius: 0.3125rem;
  border-top-right-radius: 0.3125rem;
}

.nav-tabs .nav-link:hover,
.nav-tabs .nav-link:focus {
  border-color: #f8fafd #f8fafd #e7eaf3;
}

.nav-tabs .nav-link.disabled {
  color: #8c98a4;
  background-color: transparent;
  border-color: transparent;
}

.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  color: #677788;
  background-color: #fff;
  border-color: #e7eaf3 #e7eaf3 #fff;
}

.nav-tabs .dropdown-menu {
  margin-top: -0.0625rem;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.nav-pills .nav-link {
  border-radius: 0.3125rem;
}

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #fff;
  background-color: #377dff;
}

.nav-fill .nav-item {
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  text-align: center;
}

.nav-justified .nav-item {
  -ms-flex-preferred-size: 0;
  flex-basis: 0;
  -ms-flex-positive: 1;
  flex-grow: 1;
  text-align: center;
}

.tab-content > .tab-pane {
  display: none;
}

.tab-content > .active {
  display: block;
}

/*------------------------------------
  Navs
------------------------------------*/
.nav .nav-link {
  color: #677788;
}

.nav .nav-link:not(.active):hover {
  color: #377dff;
}

.nav:not(.nav-pills) .nav-link.active {
  color: #377dff;
}

/* Small Size */
.nav-sm .nav-link {
  font-size: 0.875rem;
}

/* No Gutters */
.nav-x-0 .nav-link {
  padding-right: 0;
  padding-left: 0;
}

.nav-y-0 .nav-link {
  padding-top: 0;
  padding-bottom: 0;
}

.nav-x-sm .nav-link {
  padding-right: 0.25rem;
  padding-left: 0.25rem;
}

/* Shadow */
.nav-shadow {
  box-shadow: 0px 3px 6px 0px rgba(140, 152, 164, 0.25);
}

/* Classic */
.nav-classic {
  border-bottom: 0.0625rem solid #e7eaf3;
}

.nav-classic .nav-link {
  color: #677788;
  border-width: 0 0 0.1875rem 0;
  border-style: solid;
  border-color: transparent;
  padding: 1rem 1.5rem;
  margin-bottom: -0.125rem;
  border-radius: 0;
  transition: 0.3s;
}

.nav-classic .nav-link:hover {
  color: #377dff;
}

.nav-classic .nav-link.active {
  color: #377dff;
  border-bottom-color: #377dff;
}

/* Rounded */
.nav-rounded {
  border-radius: 0.3125rem;
}

.nav-rounded .nav-item:first-child .nav-link {
  border-bottom-left-radius: 0.3125rem;
}

.nav-rounded .nav-item:last-child .nav-link {
  border-bottom-right-radius: 0.3125rem;
}

/* White Color */
.nav-white .nav-link {
  color: rgba(255, 255, 255, 0.7);
}

.nav-white .nav-link.active {
  color: #377dff;
  background-color: #fff;
}

.nav-white .nav-link:not(.active):hover {
  color: #fff;
}

/* White Border Color */
.nav.nav-border-white .nav-link {
  color: rgba(255, 255, 255, 0.7);
  border-bottom: 0.125rem solid transparent;
}

.nav.nav-border-white .nav-link.active {
  color: #fff;
  border-bottom-color: #fff;
}

.nav.nav-border-white .nav-link:not(.active):hover {
  color: #fff;
}

/* Box */
.nav-box .nav-link {
  color: #1e2022;
  border-radius: 0.3125rem;
}

.nav-box .nav-link.active {
  color: #377dff;
  background-color: #fff;
  box-shadow: 0px 12px 15px rgba(140, 152, 164, 0.1);
}

/* Segment */
.nav-segment {
  position: relative;
  background-color: #f8fafd;
  padding: 0.25rem 0.25rem;
  border-radius: 0.3125rem;
}

.nav-segment:not(.nav-fill) {
  display: -ms-inline-flexbox;
  display: inline-flex;
}

.nav-segment .nav-link {
  color: #677788;
  font-size: 0.875rem;
  font-weight: 600;
  padding: 0.625rem 1.125rem;
  border-radius: 0.3125rem;
}

.nav-segment .nav-link:hover {
  color: #377dff;
}

.nav-segment .nav-link.active {
  color: #1e2022;
  background-color: #fff;
  box-shadow: 0px 3px 6px 0px rgba(140, 152, 164, 0.25);
}

.nav-segment.nav-pills {
  border-radius: 6.1875rem;
}

.nav-segment.nav-pills .nav-link {
  border-radius: 6.1875rem;
}

.dropcontainer {
  display: flex;
  flex-direction: column;
  font-family: sans-serif;
}

.dropcontainer > p {
  font-size: 1rem;
}

.dropcontainer > em {
  font-size: 0.8rem;
}

.dropzone {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  border-width: 2px;
  border-radius: 2px;
  border-color: $teal;
  border-style: dashed;
  background-color: $white;
  color: $gray-600;
  outline: none;
  transition: border 0.24s ease-in-out;
}

.dropzone:focus {
  border-color: $teal;
}

.dropzone.disabled {
  opacity: 0.6;
}
